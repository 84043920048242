//importando jquery
import $ from 'jquery';

// jquery para label dos inputs
$(document).ready(function () {
    const inputs = document.querySelectorAll(".primary-input");

    const handleFocus = ({
        target
    }) => {
        const label = target.previousElementSibling;
        label.classList.remove("text-sm");
        label.classList.remove("top-2/4");
        label.classList.remove("left-3");
        label.classList.add("text-xs");
        label.classList.add("top-0");
        label.classList.add("left-6");
        label.classList.add("border-[1.5px]");
        label.classList.add("border-[#08246c]");
    };

    const handleFocusOut = ({
        target
    }) => {
        if (target.value === "") {
            const label = target.previousElementSibling;
            label.classList.remove("text-xs");
            label.classList.remove("top-0");
            label.classList.remove("left-6");
            label.classList.remove("border-[1.5px]");
            label.classList.remove("border-[#08246c]");
            label.classList.add("text-sm");
            label.classList.add("top-2/4");
            label.classList.add("left-3");
        }
    };

    inputs.forEach((input) => input.addEventListener("focus", handleFocus));
    inputs.forEach((input) => input.addEventListener("focusout", handleFocusOut))

    //deixar label em cima se a página já carregar com o input preenchido
    inputs.forEach((input) => {
        if (input.value != "") {
            const label = input.previousElementSibling;
            label.classList.remove("text-sm");
            label.classList.remove("top-2/4");
            label.classList.remove("left-3");
            label.classList.add("text-xs");
            label.classList.add("top-0");
            label.classList.add("left-6");
            label.classList.add("border-[1.5px]");
            label.classList.add("border-[#08246c]");
        }
    });
});

window.addEventListener('load', function () {
    var balao = document.getElementById('balao');
    if (balao != null) {
        setTimeout(function () {
            balao.classList.add('opacity-0');
        }, 3000);
        setTimeout(function () {
            balao.classList.add('hidden');
        }, 5000);
    }
});


